<template>
  <div class="mod-group mod-base">
    <div class="cell-header">
      <h4>押金申退信息</h4>
    </div>
    <div class="cell-body">
      <slot></slot>
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
        </span>
        <span slot="payType" slot-scope="text">
          {{ text | bank }}
        </span>
        <span slot="payTime" slot-scope="text">
          {{ text | formatTime('YYYY-MM-DD HH:mm:ss') }}
        </span>
        <span slot="payStatus" slot-scope="text">
          {{ text | payStatus }}
        </span>
        <div slot="refundPurpose" slot-scope="text, record, index">
          <a-select
            v-if="editable"
            :value="text"
            :options="refundPurposes"
            @change="e => onChange('use', e, index)"
            ></a-select>
          <span v-else>{{ text | refundPurpose }}</span>
        </div>
        <span slot="refundPaymentStatus" slot-scope="text">
          {{ text | refundStatus }}
        </span>
        <div slot="repayAmount" slot-scope="text, record, index">
          <a-input
            v-if="editable"
            :value="text"
            type="number"
            @blur="e => onChange('repayAmount', e.target.value, index)"
            ></a-input>
          <span v-else>{{ text }}</span>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import { refundPurpose as refundPurposes } from '@/dicts/order'
import { refundStatus, refundPurpose } from '@/filters/order'
import { bank, status as payStatus } from '@/filters/pay'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          scopedSlots: { customRender: 'index' }
        },
        {
          title: '支付流水号',
          dataIndex: 'tradeNo'
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          scopedSlots: { customRender: 'payType' }
        },
        {
          title: '支付金额',
          dataIndex: 'payAmount'
        },
        {
          title: '支付时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' }
        },
        {
          title: '支付状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' }
        },
        // {
        //   title: '申退金额',
        //   dataIndex: 'repayAmount',
        //   scopedSlots: { customRender: 'repayAmount' }
        // },
        // {
        //   title: '手续费',
        //   dataIndex: 'refundFee'
        // },
        // {
        //   title: '审核退款金额',
        //   dataIndex: 'actualRefundAmount'
        // },
        {
          title: '已退',
          dataIndex: 'refundedAmount'
        },
        // {
        //   title: '退款用途',
        //   dataIndex: 'use',
        //   scopedSlots: { customRender: 'refundPurpose' }
        // },
        {
          title: '退款时间',
          dataIndex: 'refundPaymentTime'
        },
        {
          title: '退款状态',
          dataIndex: 'refundPaymentStatus',
          scopedSlots: { customRender: 'refundPaymentStatus' }
        },
        {
          title: '退款流水',
          dataIndex: 'refundPaymentNo'
        }
      ],
      refundPurposes
    }
  },
  props: {
    list: {
      type: Array,
      default: function () {
        return []
      }
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange (col, value, index) {
      this.$emit('change', col, value, index)
    }
  },
  filters: {
    bank,
    payStatus,
    refundStatus,
    refundPurpose
  }
}
</script>
