<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4 style="float: left; margin-right: 20px;">订单PNR信息</h4>
      <slot></slot>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="id"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        bordered
      >
        <span slot="index" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="orgDst" slot-scope="text">
          <div v-for="(segment, index) in text" :key="index">
            {{ segment.depCode | airCity }} - {{ segment.arrCode | airCity }}
          </div>
        </span>
      </a-table>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { airCity } from '@/filters/flight'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          key: 'index',
          scopedSlots: { customRender: 'index' },
          align: 'center'
        },
        {
          title: 'PNR编码',
          dataIndex: 'pnr'
        },
        {
          title: '航段',
          dataIndex: 'segmentInfos',
          key: 'orgDst',
          scopedSlots: { customRender: 'orgDst' }
        },
        {
          title: '人数',
          dataIndex: 'cnt'
        }
      ]
    }
  },
  props: {
    list: Array
  },
  filters: {
    airCity
  }
}
</script>
