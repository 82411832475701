<template>
  <div class="page-b2b-refund-deposit-detail">
    <div class="loading" v-if="loading">
      <a-spin size="large"></a-spin>
    </div>
    <template v-else>
      <div class="mod-group mod-base">
        <div class="cell-header">
          <h4>基础信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>提交人:</th>
              <td>{{ refund.submitter }}</td>
              <th>提交人账户:</th>
              <td>{{ refund.submitAccount }}</td>
              <th>机票订单号:</th>
              <td>{{ data.orderNo }}</td>
            </tr>
            <tr>
              <th>航段:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}
                </div>
              </td>
              <th>航班日期:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  {{ segment.depTime | format('YYYY-MM-DD') }}
                </div>
              </td>
              <th>人数:</th>
              <td>{{ data.leftGroupQty }} (<span style="color: red;">初始成交数量: {{ data.intGroupQty }}</span>)</td>
            </tr>
            <tr>
              <th>单价:</th>
              <td>{{ data.statisticInfo.totalUnitNetFare}}</td>
              <th>总票额:</th>
              <td>{{ data.statisticInfo.totalNetFare }}</td>
              <th>机场建设费总额:</th>
              <td>{{ data.statisticInfo.totalAirportTax }}</td>
            </tr>
            <tr>
              <th>手续费:</th>
              <td>{{ data.statisticInfo.totalRefundFee }}</td>
              <th>燃油费总额:</th>
              <td>{{ data.statisticInfo.totalFuelTax}}</td>
              <th>总金额:</th>
              <td>{{ data.statisticInfo.totalActualAmount }}</td>
            </tr>
            <tr>
              <th>航班起飞时间:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}: {{ segment.depTime | format('YYYY-MM-DD HH:mm') }}
                </div>
              </td>
              <th>申退时间距航班起飞时间:</th>
              <td>
                <div v-for="segment in data.segments" :key="segment.id">
                  {{ segment.depCode | airCity }}-{{ segment.arrCode | airCity }}: {{ segment.depTime | leftTime(refund.createTime) }}
                </div>
              </td>
              <th>团队折扣率:</th>
              <td>{{ data.teamDiscount !== 'undefined' ? `${data.teamDiscount}%` : '' }}</td>
            </tr>
          </table>
        </div>
      </div>

      <deposit-pnr v-if="data.pnrs && data.pnrs.length" :list="data.pnrs"></deposit-pnr>

      <deposit-payment :data="refund" :list="data.refundPayment" @change="onPaymentChange">
        <table class="table-infomation" style="margin-bottom: 12px;">
          <tr>
            <th>申退单号:</th>
            <td>{{ refund.refundNo }}</td>
            <th>申退原因:</th>
            <td>{{ refund.refundReason }}</td>
            <th>申退时间:</th>
            <td>{{ refund.createTime }}</td>
            <th>备注:</th>
            <td>{{ refund.descp }}</td>
          </tr>
          <tr>
            <th>申退金额:</th>
            <td>{{ refund.repayAmount }}</td>
            <th>手续费:</th>
            <td>{{ refund.refundFee }}</td>
            <th>审核退款金额:</th>
            <td>{{ refund.actualRefundAmount }}</td>
            <th>退款用途:</th>
            <td>{{ refund.refundPurpose }}</td>
          </tr>
        </table>
      </deposit-payment>

      <div class="mod-group">
        <div class="cell-header">
          <h4>匹配退押金规则</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>团队折扣率:</th>
              <td>{{ refund.discountFrom ? `${refund.discountFrom}%` : '0' }} - {{ refund.discountTo ? `${refund.discountTo}%` : '0' }}</td>
              <th>距航班起飞时间:</th>
              <td>{{ refund.refundHoursFrom }} - {{ refund.refundHoursTo }}小时</td>
              <th>手续费率:</th>
              <td>{{ refund.refundRate ? `${refund.refundRate * 100}%` : '' }}</td>
            </tr>
            <tr>
              <th></th>
              <td colspan="3">{{ refund.refundFeeRuleDesc }}</td>
            </tr>
          </table>
        </div>
      </div>
      <!-- <admin-flow-table v-if="audits && audits.length" :list="audits"></admin-flow-table> -->
    </template>
  </div>
</template>

<script>
import moment from 'moment'
import queryString from 'query-string'
import { fetchRefundDepositOrderDetail, clearPnr } from '@/api/order'
import { airCity } from '@/filters/flight'
import { format } from '@/filters/time'
import { refundDepositType as refundDepositTypes } from '@/dicts/order'
import DepositPnr from './components/deposit-pnr'
import DepositPayment from './components/deposit-payment'

import './css/refund-deposit-detail.less'

export default {
  data () {
    return {
      data: {},
      refund: {},
      refundReason: '',
      loading: false,
      audits: null,
      confirmLoading: false,
      refundDepositTypes: refundDepositTypes.slice(1)
    }
  },
  created () {
    const params = queryString.parse(location.search)

    this.fetchDetail(params.id)
  },
  methods: {
    async fetchDetail (id) {
      this.loading = true

      try {
        const { bizData, auditHis } = await fetchRefundDepositOrderDetail({
          id
        })

        this.data = bizData
        this.refund = bizData.refund ? bizData.refund[0] : {}
        this.audits = auditHis
        this.loading = false
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    onPaymentChange (key, value, index) {
      const { data } = this

      data.refundPayment.splice(index, 1, {
        ...data.refundPayment[index],
        [key]: value
      })
    },
    onClearPnrChange (e) {
      const checked = e.target.checked
      this.$confirm({
        content: '确认改变已清位状态吗？',
        onOk: async () => {
          try {
            await clearPnr({
              refundId: this.refund.id,
              confirmClearPnr: checked
            })

            this.$message.success('更新成功')
            this.refund.confirmClearPnr = checked
          } catch (err) {
            this.$message.error(err.message)
          }
        }
      })
    },
    // 审批
    async onSubmit () {
      try {
        const { data, refundReason } = this

        data.refund[0].refundReason = refundReason

        this.confirmLoading = true
        await this.$refs.flowAction.submit(data)

        this.$message.success('审核成功，即将刷新页面', () => {
          location.reload()
        })
      } catch (err) {
        this.confirmLoading = false
        this.$message.error(err.message)
      }
    }
  },
  filters: {
    airCity,
    format,
    leftTime (value, createTime) {
      if (!value) {
        return ''
      }

      const time = (moment(value).valueOf() - moment(createTime).valueOf()) / 1000
      const day = Math.floor(time / (24 * 3600))
      const hour = Math.floor(time / 3600) - (day * 24)
      const minute = Math.floor(time / 60) - day * 24 * 60 - hour * 60

      return `${day ? day + '天' : ''}${hour}小时${minute}分钟`
    }
  },
  components: {
    DepositPnr,
    DepositPayment
  }
}
</script>
